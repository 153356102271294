<template>
  <div class="container-fluid py-4">
    <div class="row">
      <div class="col-lg-8 mx-auto">
        <div class="card my-5">
          <div class="card-header p-3 pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6>CEK TARIF</h6>
                <p class="text-sm mb-0">
                  Masukkan data alamat asal, tujuan, dan berat paket untuk
                  mendapatkan tarif harga pengiriman.
                </p>
              </div>
            </div>
          </div>
          <div class="card-body p-3 mt-3">
            <hr class="horizontal dark mt-0 mb-2" />

            <div class="row">
              <div class="col pt-4"><h5>Masukkan Data Paket</h5></div>
              <div class="col text-end">
                <argon-button
                  color="primary"
                  variant="outline"
                  class="my-4 mb-2"
                  size="sm"
                  @click.prevent="modals.classic2 = true"
                  >Gunakan Official Dropship Store</argon-button
                >
              </div>
            </div>
            <form @submit.prevent="onSubmit">
              <div class="row">
                <div class="col-md-5">
                  <div>
                    <label class="form-label"
                      >Asal <small class="text-muted">kecamatan</small></label
                    >
                    <AutocompleteZonecode
                      v-if="showAutocompleteOrigin"
                      id="origin"
                      name="origin"
                      name-i-d="origin_id"
                      clasname="form-control form-control-lg"
                      :urlsearch="urlsearch"
                      :value-i-d="form.origin_id"
                      :value-area="form.origin"
                      get_district_id="no"
                      @autocompletecallback="handleAutocompleteCallback"
                      @autocompleteloadingcallback="handleAutocompleteLoadingCallback"
                    />
                  </div>
                </div>
                <div class="col-md-5">
                  <label class="form-label"
                    >Tujuan <small class="text-muted">kecamatan</small></label
                  >
                  <AutocompleteZonecode
                    id="destination"
                    name="destination"
                    name-i-d="destination_id"
                    clasname="form-control form-control-lg"
                    :urlsearch="urlsearch"
                    :value-i-d="form.destination_id"
                    :value-area="form.destination"
                    get_district_id="no"
                  />
                </div>
                <div class="col-md-2">
                  <label class="form-label"
                    >Berat <small class="text-muted">gram</small></label
                  >
                  <input
                    v-model="form.weight"
                    name="weight"
                    type="number"
                    class="form-control form-control-lg"
                  />
                </div>
              </div>

              <div class="mt-4">
                <argon-checkbox
                  id="checkboxOpsiDimensi"
                  name="opsiDimensi"
                  @click="handleOpsiDimensi"
                  >Opsi Dimensi</argon-checkbox
                >
                <div v-if="showOpsiDimensi" class="row">
                  <div class="col-md-3">
                    <label class="form-label"
                      >Panjang <small class="text-muted">cm</small></label
                    >
                    <input
                      v-model="form.dim_length"
                      name="length"
                      type="number"
                      class="form-control form-control-lg"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label"
                      >Lebar <small class="text-muted">cm</small></label
                    >
                    <input
                      v-model="form.dim_width"
                      name="width"
                      type="number"
                      class="form-control form-control-lg"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-label"
                      >Tinggi <small class="text-muted">cm</small></label
                    >
                    <input
                      v-model="form.dim_height"
                      name="height"
                      type="number"
                      class="form-control form-control-lg"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-4">
                <argon-checkbox
                  id="checkboxOpsiCOD"
                  name="opsiCOD"
                  @click="handleOpsiCOD"
                  >Opsi COD</argon-checkbox
                >
                <div v-if="showOpsiCOD" class="row">
                  <div class="col-md-3">
                    <label class="form-label"
                      >Custom Nilai COD <small class="text-muted">Rp</small></label
                    >
                    <input
                      v-model="form.input_custom_cod"
                      name="price"
                      type="number"
                      class="form-control form-control-lg"
                      placeholder="15000"
                    />
                  </div>
                  <div class="col-md-3">
                    <label class="form-check-label">Opsi</label>
                    <argon-checkbox
                      id="checkboxOpsiAsuransi"
                      name="checkboxOpsiAsuransi"
                      @click="handleOpsiAsuransi"
                      >Asuransi</argon-checkbox
                    >
                  </div>
                </div>
              </div>
              <div class="text-center">
                <argon-button
                  color="primary"
                  variant="gradient"
                  class="my-4 mb-2"
                  :loading="isLoading"
                  >CEK TARIF</argon-button
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <modal v-model:show="modals.classic" size="lg">
    <div class="p-2">
      <div class="row">
        <div class="col-md-6 text-sm">Asal:<br /><b>{{ form.origin }}</b></div>
        <div class="col-md-6 text-sm">Tujuan:<br /><b>{{ form.destination }}</b></div>
      </div>
      <div class="row mt-2">
        <div class="col-md-1"></div>
        <div :class="csshtmlDisplayTariff">
          <span v-html="htmlDisplayTariff"></span>
        </div>
        <div class="col-md-1"></div>
      </div>

      <hr style="margin-bottom: -20px" />
      <argon-button color="primary" variant="gradient" class="my-4 mb-2" @click.prevent="CreateOrderSendingPackage"
        >Buat Pesanan</argon-button
      >
      &nbsp;
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic = false"
      >
        Close
      </button>
    </div>
  </modal>
  <modal v-model:show="modals.classic2" size="lg">
    <div class="p-2">
      <h5>Official Dropship Store</h5>
      <div class="row p-4">
        <div
          v-for="officialDropship in officialDropshipStore"
          :key="officialDropship.code"
          class="col-md-6 card"
        >
          <div class="card-body">
            <b>{{ officialDropship.name }}</b>
            <p>
              {{ officialDropship.address }}<br />
              {{ officialDropship.phone }}, {{ officialDropship.email }}
            </p>

            <argon-button
              color="primary"
              size="sm"
              variant="gradient"
              class="my-4 mb-2"
              @click="selectedOfficialDropshipStore(officialDropship)"
              >Gunakan</argon-button
            >
          </div>
        </div>
      </div>

      <hr style="margin-bottom: -20px" />
      <button
        type="button"
        class="btn btn-link"
        style="margin-top: 35px"
        @click="modals.classic2 = false"
      >
        Close
      </button>
    </div>
  </modal>
</template>

<script>
import axios from "axios";
import Modal from "@/components/Modal";
import Swal from "sweetalert2";
import ArgonCheckbox from "@/components/ArgonCheckbox.vue";
import ArgonButton from "@/components/ArgonButton.vue";
import AutocompleteZonecode from "@/components/AutocompleteZonecode.vue";
import { ElLoading } from "element-plus";

export default {
  name: "TariffChecking",
  components: {
    ArgonCheckbox,
    ArgonButton,
    AutocompleteZonecode,
    Modal,
  },
  data() {
    return {
      modals: {
        classic: false,
        classic2: false,
      },
      urlread: "/api/v1/zonecodes",
      urlsearch: "/api/v1/search_zonecodes",
      urltariff: "/api/v1/courier_tariff",
      showAutocompleteOrigin: true,
      isLoading: false,
      showOpsiDimensi: false,
      showOpsiCOD: false,
      form: {
        official_dropship_store_id: "",
        origin_id: "",
        origin: "",
        destination: "",
        destination_id: "",
        weight: 1000,
        dim_length: 25,
        dim_width: 15,
        dim_height: 7,
        price: 0,
        opsiDimensi: false,
        opsiCOD: false,
        opsiAsuransi: false,
        input_custom_cod: 145000,
        cart_product_items: [],
      },
      officialDropshipStore: [],
      selected: {
        officialDropshipStore: [],
      },
      search: "",
      filteredSearch: [],
      autocompleteLoading: false,
      results_tariff: [],
      htmlDisplayTariff: '',
      csshtmlDisplayTariff: 'col-md-10 csshtmlDisplayTariff',
    };
  },
  created() {
    this.getOfficialDropshipStore();
  },
  methods: {
    loadingService() {
      const loading = ElLoading.service({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.1)",
      });

      return loading;
    },
    async getOfficialDropshipStore() {
      this.loadingService();
      try {
        const response = await axios.get(
          "api/v1/official_dropship_store/showlistaddress",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        );
        this.officialDropshipStore = response.data.datatables;
        this.loadingService().close();
      } catch (error) {
        console.log(error);
        this.loadingService().close();
        this.errorRedirect500(error);
      }
    },
    async onSubmit(values) {
      this.isLoading = true;
      this.form.origin_id = values.target[0].value;
      this.form.origin = values.target[1].value;
      this.form.destination_id = values.target[2].value;
      this.form.destination = values.target[3].value;
      try {
        const response = await axios({
          method: "post",
          url: this.urltariff,
          headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          data: this.form,
        });
        this.isLoading = false;
        if (response.data.status == 'success') {
          this.modals.classic = true;
          this.results_tariff = response.data;
          this.htmlDisplayTariff = response.data.html_display_tariff;
          if(response.data.total_results_tariff >= 4) {
            this.csshtmlDisplayTariff = 'col-md-10 csshtmlDisplayTariff';
          }else{
            this.csshtmlDisplayTariff = 'col-md-10';
          }
        } else {
          Swal.fire(
            "Warning!",
            response.data.message,
            "warning"
          );
        }
      } catch (error) {
        this.isLoading = false;
        console.log(error);
        Swal.fire(
          "Warning!",
          error.response.data.message,
          "warning"
        );        
        this.errorRedirect500(error);
      }
    },
    handleOpsiDimensi(values) {
      this.form.opsiDimensi = values.target.checked;
      if (this.form.opsiDimensi == true) {
        this.showOpsiDimensi = true;
      } else {
        this.showOpsiDimensi = false;
      }
    },
    handleOpsiCOD(values) {
      this.form.opsiCOD = values.target.checked;
      if (this.form.opsiCOD == true) {
        this.showOpsiCOD = true;
      } else {
        this.showOpsiCOD = false;
      }
    },
    handleOpsiAsuransi(values) {
      this.form.opsiAsuransi = values.target.checked;
    },
    selectedOfficialDropshipStore(officialDropship) {
      this.modals.classic2 = false;
      
      this.selected.officialDropshipStore = officialDropship;
      this.form.official_dropship_store_id = officialDropship.id,
      this.form.origin_id = officialDropship.ro_subdistrict_id;
      this.form.origin = officialDropship.area;
      this.showAutocompleteOrigin = false;
      setTimeout(() => {
        this.showAutocompleteOrigin = true;
      }, 50);

      console.log(
        officialDropship.ro_subdistrict_id + " " + officialDropship.area
      );
    },
    CreateOrderSendingPackage() {
      //this.$router.push({ name: 'SendPackages', params: { results_tariff: this.results_tariff } });
      if(this.selected.officialDropshipStore != '') {
        this.$router.push({ name: 'CreateSendPackages', params: { mode: 'official', selected: JSON.stringify(this.selected.officialDropshipStore)} })
      }else{
        this.$router.push({ name: 'CreateSendPackages', params: { mode: 'manual'} })
      }
    },
    handleAutocompleteCallback(values) {
      console.log(values);
    },
    handleAutocompleteLoadingCallback(values) {
      if(values == true) {
        this.selected.officialDropshipStore = [];
      }
    },
    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
<style scoped>
.csshtmlDisplayTariff {
  height:400px;
  overflow-y:scroll;
}
</style>